import React, { useState } from "react"
import { addPropertyControls } from "framer"
import RiveComponent, {
    componentName,
    propertyControls,
} from "https://rive.app/api/s/OeLqF8hxyEuM61hZUDDd_A/framer/"

import { useScroll, useMotionValueEvent } from "framer-motion"

function App(props) {
    const { scrollYProgress } = useScroll()
    const [scrollPct, setScrollPct] = useState(0)

    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        console.log("Page scroll: ", latest)
        setScrollPct(latest * 0)
    })

    return <RiveComponent {...props} number1={scrollPct} />
}

/**
 
@framerSupportedLayoutWidth any-prefer-fixed
@framerSupportedLayoutHeight any-prefer-fixed
*/
export default App as React.ComponentType

App.displayName = componentName
addPropertyControls(App, propertyControls)
shouldDisableRiveListeners: true
